require('../scss/_style.scss');

var refreshRate = 84600; // 1 day
var defaultLang = "de";

$(document).ready(function() {
  var language = navigator.language;
  var welcomeScreen = $(".app-welcome-screens");
  refreshRate = welcomeScreen.data("refresh");
  defaultLang = welcomeScreen.data("lang");

  if (document.cookie.indexOf("welcomeScreen") == -1) {
    showScreen(language);
  } 

  $('.app-welcome-screen-close').click(function() {
    hideScreen(true);
  });

  $('.delete-cookie').click(function() {
    deleteCookie();
  });

  $('.small-flags .flag').click(function() {
    var lang = $(this).data("lang");
    hideScreen(false);
    showScreen(lang, 0);
  });
})

function showScreen(language: string, timeout: number = 1000) {
  var screen = $(".app-welcome-screen.lang-" + language);
  $(".app-welcome-screens").show();

  if (screen.length == 0) {
    screen = $(".app-welcome-screen.lang-" + defaultLang);
  }
  
  screen.addClass("active");
  
  setTimeout(() => {
    screen.addClass("ready");
  }, timeout);
}

function hideScreen(setCk: boolean) {
  $(".app-welcome-screens").hide();
  $(".app-welcome-screen").removeClass("active");
  
  if(setCk)
    setCookie();
}

function deleteCookie() {
  document.cookie = "welcomeScreen=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function setCookie() {
  var now = new Date();
  // Calculate expiry time - 1 day from current time
  now.setTime(now.getTime() + 1 * 24 * 60 * 60 * 1000);
  var expires = "expires=" + now.toUTCString();
  document.cookie = `welcomeScreen=true; ${expires}; path=/; secure;`;
}